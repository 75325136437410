import request from '@/utils/request'

export function getStocktakingPlans(params) {
  return request({
    method: 'get',
    url: 'stocktaking-plan',
    params
  })
}

export function getStocktakingPlanReports(params) {
  return request({
    method: 'get',
    url: 'stocktaking-plan/report',
    params
  })
}

export function getStocktakingPlanHistories(params) {
  return request({
    method: 'get',
    url: 'stocktaking-plan/history',
    params
  })
}
