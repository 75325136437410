var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": "盘点计划",
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('van-sticky', {
    attrs: {
      "offset-top": "1.22667rem"
    }
  }, [_c('van-cell', {
    attrs: {
      "title": "门店",
      "value": _vm.storeName,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showActions = true;
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "开始时间",
      "value": _vm.listQuery.begin_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleChooseDate('begin');
      }
    }
  }), _c('van-cell', {
    attrs: {
      "title": "结束时间",
      "value": _vm.listQuery.end_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        return _vm.handleChooseDate('end');
      }
    }
  })], 1), _vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    ref: "list",
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, _vm._l(_vm.list, function (plan, index) {
    return _c('div', {
      key: index,
      staticClass: "plan"
    }, [_c('van-cell', {
      attrs: {
        "title": "创建时间",
        "value": plan.created_at
      }
    }), _c('van-cell', {
      attrs: {
        "title": "盘点状态"
      }
    }, [[_c('van-tag', {
      attrs: {
        "type": plan.is_finished ? 'success' : 'danger'
      }
    }, [_vm._v(_vm._s(plan.is_finished ? '已完成' : '未完成'))])]], 2), plan.is_finished ? _c('van-cell', {
      attrs: {
        "title": "盘点人",
        "value": plan.employee.name
      }
    }) : _vm._e(), plan.is_finished ? _c('van-cell', {
      attrs: {
        "title": "开始盘点",
        "value": plan.begin_at
      }
    }) : _vm._e(), plan.is_finished ? _c('van-cell', {
      attrs: {
        "title": "结束盘点",
        "value": plan.end_at
      }
    }) : _vm._e(), plan.is_finished ? _c('van-cell', {
      attrs: {
        "title": "共用时",
        "value": "".concat(plan.use_minutes, "\u5206\u949F")
      }
    }) : _vm._e(), _c('div', {
      staticClass: "goods"
    }, [_c('div', [_c('van-image', {
      staticClass: "goods-image",
      attrs: {
        "src": plan.goods.product.image
      }
    })], 1), _c('div', {
      staticClass: "goods-info"
    }, [_c('div', {
      staticClass: "goods-name"
    }, [_vm._v(_vm._s(plan.goods.product.name))]), _c('div', {
      staticClass: "goods-size"
    }, [_c('span', [_vm._v("规格：1 * " + _vm._s(plan.goods.product.size))])]), _c('div', {
      staticClass: "goods-storage"
    }, [_c('span', [_vm._v("储位：" + _vm._s(plan.goods.default_in_warehouse.storage))])])])])], 1);
  }), 0), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showDatePicker,
      callback: function callback($$v) {
        _vm.showDatePicker = $$v;
      },
      expression: "showDatePicker"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择年月日"
    },
    on: {
      "confirm": _vm.handleDateConfirm,
      "cancel": function cancel($event) {
        _vm.showDatePicker = false;
      }
    },
    model: {
      value: _vm.pickerDate,
      callback: function callback($$v) {
        _vm.pickerDate = $$v;
      },
      expression: "pickerDate"
    }
  })], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.actions
    },
    on: {
      "select": _vm.handleSelectAction
    },
    model: {
      value: _vm.showActions,
      callback: function callback($$v) {
        _vm.showActions = $$v;
      },
      expression: "showActions"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }