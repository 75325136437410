<template>
  <div>
    <my-nav-bar
      title="盘点计划"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container">
      <van-sticky offset-top="1.22667rem">
        <van-cell title="门店" :value="storeName" is-link center @click="showActions = true" />
        <van-cell title="开始时间" :value="listQuery.begin_date" is-link center @click="handleChooseDate('begin')" />
        <van-cell title="结束时间" :value="listQuery.end_date" is-link center @click="handleChooseDate('end')" />
      </van-sticky>
      <van-empty v-if="showEmpty" description="暂无数据" />
      <van-list ref="list" v-model="loading" class="list" :finished="finished" @load="getList">
        <div v-for="(plan, index) in list" :key="index" class="plan">
          <van-cell title="创建时间" :value="plan.created_at" />
          <van-cell title="盘点状态">
            <template>
              <van-tag :type="plan.is_finished ? 'success' : 'danger'">{{ plan.is_finished ? '已完成' : '未完成' }}</van-tag>
            </template>
          </van-cell>
          <van-cell v-if="plan.is_finished" title="盘点人" :value="plan.employee.name" />
          <van-cell v-if="plan.is_finished" title="开始盘点" :value="plan.begin_at" />
          <van-cell v-if="plan.is_finished" title="结束盘点" :value="plan.end_at" />
          <van-cell v-if="plan.is_finished" title="共用时" :value="`${plan.use_minutes}分钟`" />
          <div class="goods">
            <div>
              <van-image :src="plan.goods.product.image" class="goods-image" />
            </div>
            <div class="goods-info">
              <div class="goods-name">{{ plan.goods.product.name }}</div>
              <div class="goods-size">
                <span>规格：1 * {{ plan.goods.product.size }}</span>
              </div>
              <div class="goods-storage">
                <span>储位：{{ plan.goods.default_in_warehouse.storage }}</span>
              </div>
            </div>
          </div>
        </div>
      </van-list>

      <van-popup v-model="showDatePicker" position="bottom">
        <van-datetime-picker
          v-model="pickerDate"
          type="date"
          title="选择年月日"
          @confirm="handleDateConfirm"
          @cancel="showDatePicker = false"
        />
      </van-popup>

      <van-action-sheet v-model="showActions" :actions="actions" @select="handleSelectAction" />

    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getStocktakingPlanReports } from '@/api/stocktaking_plan'
import { toDateString } from '@/utils'
import { getStores } from '@/api/home'
export default {
  components: { myNavBar },
  data() {
    return {
      listQuery: {
        page: 0,
        store_id: ''
      },
      storeName: '',
      list: [],
      showEmpty: false,
      finished: false,
      loading: false,
      dateType: '',
      showDatePicker: false,
      pickerDate: new Date(),
      showActions: false,
      actions: []
    }
  },
  created() {
    getStores().then(res => {
      res.data.map(store => {
        this.actions.push({
          value: store.id,
          name: store.name
        })
      })
    })
  },
  methods: {
    getList() {
      this.listQuery.page++
      getStocktakingPlanReports(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.finished = res.data.list.length < 10
        this.loading = false
        this.showEmpty = this.list.length === 0
      })
    },
    handleChooseDate(type) {
      this.dateType = type
      this.showDatePicker = true
    },
    handleDateConfirm(date) {
      date = toDateString(date)
      if (this.dateType === 'begin') {
        this.listQuery.begin_date = date
        this.reload()
      } else {
        this.listQuery.end_date = date
        this.reload()
      }
      this.showDatePicker = false
    },
    reload() {
      this.list = []
      this.listQuery.page = 0
      this.loading = false
      this.finished = false
      this.showEmpty = false
      this.$refs.list.check()
    },
    handleSelectAction(action) {
      this.storeName = action.name
      this.listQuery.store_id = action.value
      this.showActions = false
      this.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px;
  }
  .plan {
    margin-top: 10px;
  }
  .plan:first-child {
    margin-top: 0!important;
  }
  .goods {
    background-color: #fff;
    padding: 10px;
    display: flex;
  }
  .goods-info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .goods-image {
    width: 80px;
    height: 80px;
    display: block!important;
  }
  .goods-size {
    font-size: 12px;
    color: #989898;
  }
  .goods-storage {
    font-size: 12px;
    color: #989898;
  }
</style>

